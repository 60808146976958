<template>
  <div class="d-flex full-height pos-rel">
    <v-navigation-drawer permanent>
      <v-subheader>Phases</v-subheader>
      <v-divider></v-divider>

      <v-list dense>
        <v-list-item-group v-model="planningNav" color="primary">
          <v-list-item v-for="item in phases" :key="item.title" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div
      v-if="assemblyTasks"
      class="d-flex flex-column flex-1 flex-scroll-width overflow-x-auto"
      style="width: 0"
    >
      <tasks-gantt-chart :tasks="planningTasks" :can-link="false">
        <template #ant-gantt-actions>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                small
                class="mr-2"
                :disabled="
                  previewMode ||
                  planningTasks.filter((task) => task.children.length === 0)
                    .length === 0
                "
                v-on="on"
                @click="previewQATasks()"
              >
                mdi-calendar-end
              </v-icon>
            </template>
            <span>Plan {{ selectedPhase.title }} tasks</span>
          </v-tooltip>
        </template>
        <template #task-header-items>
          <tasks-gantt-default-item
            v-for="(task, index) in planningTasks"
            :key="`ant-gantt-header-${task.id}`"
            :task="task"
            :index="index"
            :parents="[]"
            :show-labels="
              $refs['tasks-gantt'] ? $refs['tasks-gantt'].showLabels : false
            "
            @toggleCollapse="toggleTaskCollapse"
            @clickedTask="navigateToTaskDetail"
          >
          </tasks-gantt-default-item>
        </template>
      </tasks-gantt-chart>
    </div>
    <div v-else class="d-flex flex-column flex-1 align-center justify-center">
      <div v-if="planningTasks.length === 0" class="font-italic">
        Please generate the module assembly tasks first in the project assembly
        planning module
      </div>
      <ant-loading v-else />
    </div>
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fab
          class="import-preview-tasks-btn"
          color="primary"
          :class="{ 'preview-btn-displayed': previewMode }"
          style="position: absolute; right: 50px; bottom: 50px; z-index: 10"
          v-on="on"
          @click="importPreviewTasks()"
          ><v-icon>mdi-calendar-import</v-icon></v-btn
        >
      </template>
      <span>Import tasks</span>
    </v-tooltip>
  </div>
</template>

<script>
import TasksGanttChart from '@/components/Charts/TasksGanttChart.vue';
import { mapGetters } from 'vuex';
import TasksGanttDefaultItem from '@/components/Charts/TasksGanttDefaultItem.vue';
import TaskHelper from '@/services/task-helper';
import { getTasks } from '@/services/api/task.api';
import AntLoading from '@/components/AntLoading.vue';

export default {
  name: 'DhmeWkbInspectionPlanPlanning',
  components: { AntLoading, TasksGanttDefaultItem, TasksGanttChart },
  data: () => {
    return {
      phases: [
        {
          value: 'wvb',
          taskType: 'dhme-wvb-phase',
          title: 'work preparation',
          icon: 'mdi-package',
        },
        {
          value: 'prd',
          taskType: 'dhme-production-phase',
          title: 'production',
          icon: 'mdi-package-variant',
        },
        {
          value: 'ass',
          taskType: 'dhme-assembly-phase',
          title: 'assembly',
          icon: 'mdi-package-variant-closed',
        },
        {
          value: 'plts',
          taskType: 'dhme-placement-phase',
          title: 'placement',
          icon: 'mdi-package-down',
        },
      ],
      planningNav: 0,
      previewMode: false,
      assemblyTasks: null,
    };
  },
  computed: {
    ...mapGetters([
      'wkbInspectionPlanTasks',
      'wkbInspectionPlanMeasurements',
      'wkbInspectionPlanProjectRisksMeasures',
      'wkbInspectionPlanMeasurementForms',
      'wkbInspectionPlanMeasurementDocuments',
      'wkbInspectionPlanModules',
      'selectedLicense',
      'project',
      'authenticatedUser',
    ]),

    planningTasks() {
      return this.wkbInspectionPlanTasks.filter((task) =>
        [this.selectedPhase.taskType].includes(task.task_type.type)
      );
    },
    selectedPhase() {
      return this.phases[this.planningNav];
    },
  },
  watch: {
    planningNav: {
      immediate: true,
      handler(value) {
        this.previewMode = false;
        this.$store.dispatch('fetchWKBTasks', this.selectedPhase.taskType);
      },
    },
  },
  async mounted() {
    this.assemblyTasks = await getTasks(
      {
        'filter[license]': this.selectedLicense.id,
      },
      {
        advanced_filters: [
          {
            column: 'project',
            operator: '=',
            values: [this.project.id],
          },
          {
            column: 'type',
            operator: '=',
            values: ['dhme-module-assembly'],
          },
        ],
      }
    );
  },
  methods: {
    navigateToTaskDetail(task) {
      this.$router.push({ name: 'tasks-detail', params: { taskId: task.id } });
    },
    toggleTaskCollapse({ task, parents }) {
      task.collapsed = !task.collapsed;
    },
    importPreviewTasks() {
      this.$store
        .dispatch('importQATasks', {
          data: this.planningTasks.flatMap((task) => task.children),
          taskPhase: this.selectedPhase.taskType,
        })
        .then(() => {
          this.previewMode = false;
        });
    },
    getPriorityIcon(priority) {
      return TaskHelper.getPriorityIcon(priority);
    },
    getPriorityColor(priority) {
      return TaskHelper.getPriorityColor(priority);
    },
    previewQATasks() {
      // filter phase measurements
      let measurements = this.wkbInspectionPlanMeasurements.filter((item) =>
        this.wkbInspectionPlanProjectRisksMeasures.some(
          (x) =>
            x.measurement_id === item.id &&
            this.wkbInspectionPlanMeasurementForms.filter(
              (item2) =>
                item2.measurement_id === item.id &&
                item2.phase === this.selectedPhase.value
            )
        )
      );

      // set sample size per measurement
      measurements.forEach((measurement) => {
        measurement.forms = this.wkbInspectionPlanMeasurementForms.filter(
          (item) =>
            item.measurement_id === measurement.id &&
            item.phase === this.selectedPhase.value
        );
        measurement.documents =
          this.wkbInspectionPlanMeasurementDocuments.filter(
            (item) =>
              item.measurement_id === measurement.id &&
              item.phase === this.selectedPhase.value
          );
        measurement.sample_size =
          measurement.type === 'leanform'
            ? this.wkbInspectionPlanProjectRisksMeasures.find(
                (x) => x.measurement_id === measurement.id
              ).sample_size
            : 100;
      });

      // set modules per measurement
      measurements.forEach((measurement) => {
        measurement.modules = this.getModulesBasedOnSampleLevel(measurement);
      });

      this.previewMode = true;
      // create preview tasks for each module per measurement
      measurements.forEach((measurement) => {
        measurement.documents.forEach((measurementDocument) => {
          measurement.modules.forEach((module) => {
            this.createTaskPerModule(measurement, module, measurementDocument);
          });
        });
        measurement.forms.forEach((measurementForm) => {
          measurement.modules.forEach((module) => {
            this.createTaskPerModule(measurement, module, measurementForm);
          });
        });
      });
    },

    createTaskPerModule(measurement, module, formOrDoc) {
      if (formOrDoc.phase === 'ass') {
        let parent = this.assemblyTasks.find(
          (task) => task.sbscode.code === module.module_id
        );
        if (!parent) {
          this.previewMode = false;
          this.$store.commit('showNotification', {
            content: `Assembly task for module '${module.module_id}' not found`,
            color: 'warning',
          });
          return;
        }
      }
      let taskPreviewObject = {
        parent: parent?.id ?? null,
        number: module.module_id,
        title: measurement.name,
        description: measurement.description,
        priority: 'normal',
        due: null,
        assigned_to: this.authenticatedUser.id,
        project: this.project.id,
        license: this.project.license,
        sbscode: module.module_id,
        relations: [],
        labels: [],
        hasChildren: false,
        options: {
          type: 'dhme-quality-assurance',
          custom_1: formOrDoc.id,
          custom_2: measurement.type,
          custom_3: formOrDoc.phase,
          custom_4: null,
          custom_5: null,
          custom_6: module.module_id,
          custom_7: null,
          metadata: JSON.stringify(formOrDoc),
          executable: true,
        },
      };

      if (measurement.type === 'leanform') {
        taskPreviewObject.options.custom_4 = formOrDoc.lf_form;
        taskPreviewObject.options.custom_7 = formOrDoc.lf_project;
      } else if (measurement.type === 'document') {
        taskPreviewObject.default = formOrDoc.default;
      }

      let task;
      if (this.selectedPhase.taskType === 'dhme-placement-phase') {
        // calculate due based on module
        task = this.planningTasks.find(
          (task) =>
            task.id ===
            this.wkbInspectionPlanModules.find(
              (module) => module.module_id === taskPreviewObject.sbscode
            ).phase
        );
      } else {
        // calculate due based on parent
        // TODO assembly phase task find
        task = this.planningTasks.find(
          (task) => task.task_type.type === this.selectedPhase.taskType
        );
      }
      // TODO if cannot be false
      if (task) {
        taskPreviewObject.due = task.due;
        taskPreviewObject.parent = task.id;

        task.collapsed = false;
        task.children.push(taskPreviewObject);
        task.hasChildren = true;
        task.loadChildren = false;
      }
    },

    getModulesBasedOnSampleLevel(measurement) {
      switch (measurement.sample_level) {
        case 'Project':
          let shuffledModules = [...this.wkbInspectionPlanModules].sort(() => {
            return 0.5 - Math.random();
          });
          if (measurement.sample_size > 0) {
            return shuffledModules.slice(0, 1);
          } else {
            return [];
          }
        case 'Woningen':
          let shuffledHouses = [
            ...new Set(
              this.wkbInspectionPlanModules
                .filter((module) => module.house_nr)
                .map((module) => module.house_nr)
            ),
          ].sort(() => {
            return 0.5 - Math.random();
          });

          let houseMeasurePercentile = Math.ceil(
            shuffledHouses.length * (measurement.sample_size / 100)
          );

          let houses = shuffledHouses.slice(0, houseMeasurePercentile);

          let houseModules = [];
          houses.forEach((houseNr) => {
            let modules = this.wkbInspectionPlanModules
              .filter((module) => module?.house_nr === houseNr)
              .sort(() => {
                return 0.5 - Math.random();
              });

            houseModules.push(modules[0]);
          });

          return houseModules;
        case 'Bouwblokken':
          let buildBlocks = [
            ...new Set(
              this.wkbInspectionPlanModules
                .filter((module) => module.build_nr)
                .map((module) => module?.build_nr?.charAt(0))
            ),
          ];
          let buildBlocksPercentile = Math.ceil(
            buildBlocks.length * (measurement.sample_size / 100)
          );
          let measuredBlocks = buildBlocks.slice(0, buildBlocksPercentile);

          let buildBlockModules = [];

          measuredBlocks.forEach((block) => {
            let shuffledModules = [...this.wkbInspectionPlanModules]
              .filter((module) => module.build_nr.startsWith(block))
              .sort(() => {
                return 0.5 - Math.random();
              });
            buildBlockModules.push(shuffledModules[0]);
          });

          return buildBlockModules;
        case 'Types':
          let moduleTypes = [
            ...new Set(
              this.wkbInspectionPlanModules.map((module) => module.module_type)
            ),
          ];
          let moduleTypePercentile = Math.ceil(
            moduleTypes.length * (measurement.sample_size / 100)
          );
          let measuredTypes = moduleTypes.slice(0, moduleTypePercentile);

          let typeModules = [];
          measuredTypes.forEach((type) => {
            let shuffledModules = [...this.wkbInspectionPlanModules]
              .filter((module) => module.module_type === type)
              .sort(() => {
                return 0.5 - Math.random();
              });
            typeModules.push(shuffledModules[0]);
          });

          return typeModules;
        case 'Bouwlagen':
          let buildLayers = [
            ...new Set(
              this.wkbInspectionPlanModules.map((module) =>
                module?.build_nr?.substring(0, 1)
              )
            ),
          ];

          let buildLayersPercentile = Math.ceil(
            buildLayers.length * (measurement.sample_size / 100)
          );
          let measuredBuildLayers = buildLayers.slice(0, buildLayersPercentile);

          let buildLayerModules = [];
          measuredBuildLayers.forEach((layer) => {
            let shuffledModules = [...this.wkbInspectionPlanModules]
              .filter((module) => module.build_nr.charAt(1) === layer)
              .sort(() => {
                return 0.5 - Math.random();
              });
            buildLayerModules.push(...shuffledModules[0]);
          });

          return buildLayerModules;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.import-preview-tasks-btn {
  transition: 400ms ease-out;
  transform: rotateZ(360deg) scale(0);
  opacity: 0;
}
.preview-btn-displayed {
  transform: rotateZ(0) scale(1);
  opacity: 1;
}
</style>
